exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sample-altona-baptist-church-js": () => import("./../../../src/pages/sample/altona-baptist-church.js" /* webpackChunkName: "component---src-pages-sample-altona-baptist-church-js" */),
  "component---src-pages-sample-altona-evangelical-church-js": () => import("./../../../src/pages/sample/altona-evangelical-church.js" /* webpackChunkName: "component---src-pages-sample-altona-evangelical-church-js" */),
  "component---src-pages-sample-gospel-power-ministries-church-js": () => import("./../../../src/pages/sample/gospel-power-ministries-church.js" /* webpackChunkName: "component---src-pages-sample-gospel-power-ministries-church-js" */),
  "component---src-pages-sample-index-js": () => import("./../../../src/pages/sample/index.js" /* webpackChunkName: "component---src-pages-sample-index-js" */),
  "component---src-pages-sample-nimmi-hair-studio-js": () => import("./../../../src/pages/sample/nimmi-hair-studio.js" /* webpackChunkName: "component---src-pages-sample-nimmi-hair-studio-js" */),
  "component---src-pages-sample-westcity-christian-church-js": () => import("./../../../src/pages/sample/westcity-christian-church.js" /* webpackChunkName: "component---src-pages-sample-westcity-christian-church-js" */)
}

